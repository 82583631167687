import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'fd-card',
  templateUrl: './fd-card.component.html',
  styleUrls: ['./fd-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FdCardComponent {

  @Input() icon: string;

}