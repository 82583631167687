import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from 'environments/environment';

@Injectable()
export class SentryErrorLogger implements ErrorHandler {
    constructor(@Inject(PLATFORM_ID) private platformId: any) {
      if(isPlatformBrowser(this.platformId)) {
        if (environment?.SENTRY_DSN) {
            Sentry.init({
              environment: environment?.environment,
              dsn: environment?.SENTRY_DSN,
              integrations: [
                // Registers and configures the Tracing integration,
                // which automatically instruments your application to monitor its
                // performance, including custom Angular routing instrumentation
                Sentry.browserTracingIntegration()
              ],
              denyUrls: [
                // Google errors
                /translate\.googleapis\.com/i,
          
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Woopra flakiness
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i,
              ],
            
              // Set tracesSampleRate to 1.0 to capture 100%
              // of transactions for performance monitoring.
              // We recommend adjusting this value in production
              tracesSampleRate: 1.0,
            
              // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
              tracePropagationTargets: ["localhost", /^https:\/\/fair-dogs\.com/],
            });
          }
      }
  }

  handleError(error: any): void {
    if (environment.environment === 'development') {
      console.error("Error from Sentry handler", error);
    }

    if (isPlatformBrowser(this.platformId)) {
      const eventId = Sentry.captureException(error.originalError || error);
      // Do not need to show Report dialog
      // Sentry.showReportDialog({ eventId });
    }
  }
}