import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { IFdVideoPlayerSource } from '@lib/components/main.interface';

@Component({
  selector: 'fd-video-player',
  templateUrl: './fd-video-player.component.html',
})
export class FdVideoPlayerComponent {

  @Input() sources: Array<IFdVideoPlayerSource> = [];
  @Input() showControls: boolean = true;

  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
}