
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppCoreModule } from '@core/app-core.module';
import { NgxsModule } from '@ngxs/store';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppProfileUserCoverImageComponent } from './components/cover-image/cover-image.component';
import { EditCoverImageComponent } from './components/edit-cover-image/edit-cover-image.component';
import { EditUserProfileComponent } from './components/edit-user-profile/edit-user-profile.component';
import { AppPageProfileFooterComponent } from './components/footer/footer.component';
import { AppPageProfileHeaderComponent } from './components/header/header.component';
import { AppPageProfileNavbarComponent } from './components/navbar/navbar.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { UserState } from './store/user/user.state';

import { AgmCoreModule } from '@agm/core';
import { RouterModule } from '@angular/router';
import { environment } from 'environments/environment';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxMaskModule } from 'ngx-mask';
import { PopoverModule } from 'ngx-smart-popover';
import { DogCardgComponent } from '../components/dog-person-relation/components/dog-sharing/components/dog-card/dog-card.component';
import { PersonCardComponent } from '../components/dog-person-relation/components/dog-sharing/components/person-card/person-card.component';
import { CareState } from '../components/dog-person-relation/components/dog-sharing/shared/store/care.state';
import { AddEditDogDialogComponent } from './components/add-edit-dog-dialog/add-edit-dog-dialog.component';
import { AddressDialogComponent } from './components/address-dialog/address-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DescriptionDialogComponent } from './components/description-dialog/description-dialog.component';
import { DescriptionComponent } from './components/description/description.component';
import { DisplayGalleryComponent } from './components/display-gallery/display-gallery.component';
import { DisplayVideoComponent } from './components/display-video/display-video.component';
import { DogHideDeleteComponent } from './components/dog-hide-delete/dog-hide-delete.component';
import { DogListComponent } from './components/dog-list/dog-list.component';
import { DogSizeDialogComponent } from './components/dog-size-dialog/dog-size-dialog.component';
import { EmailChatComponent } from './components/email-chat/email-chat.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PaymentAlertComponent } from './components/payment-alert/payment-alert.component';
import { PictureGallaryComponent } from './components/picture-gallary/picture-gallary.component';
import { ShareDogDialogComponent } from './components/share-dog-dialog/share-dog-dialog.component';
import { SubscriptionPlansComponent } from './components/subscription/subscription-plans/subscription-plans.component';
import { TestInfoDialogComponent } from './components/test-info-dialog/test-info-dialog.component';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { UserInfoDialogComponent } from './components/user-info-dialog/user-info-dialog.component';
import { CreditState } from './store/credit/credit.state';
import { DogState } from './store/dog/dog.state';
import { DogImageGallaryState } from './store/image-gallary/image-gallary.state';
import { ProductState } from './store/product/product.state';

// modules
import { FdButtonModule, FdCardModule, FdIconModule, FdSeparatorModule, FdVideoPlayerModule } from '@lib';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UserCreditComponent } from '../components/profile-user/components/user-credit/user-credit.component';
import { BillHistoryComponent } from './components/bill-history/bill-history.component';
import { IcoSelectboxComponent } from './components/ico-selectbox/ico-selectbox.component';
import { LastAdDeleteConfPopupComponent } from './components/last-ad-delete-conf-popup/last-ad-delete-conf-popup.component';
import { CreditPlansComponent } from './components/subscription/credit-plans/credit-plans.component';
import { SubscriptionParentComponent } from './components/subscription/subscription-parent/subscription-parent.component';
import { InfoPopupComponent } from './info-popup/info-popup.component';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MembershipCardComponent } from '../components/profile-user/components/membership-card/membership-card.component';
import { SubscriptionDogSharingComponent } from './components/subscription/subscription-dogSharing/subscription-dogSharing.component';
import { ProfileSwitcher } from './components/ProfileSwitcher/profile-switcher.component';
import { TranslateModule } from '@ngx-translate/core';

const FD_IMPORTS = [
  FdVideoPlayerModule,
  FdCardModule,
  FdButtonModule,
  FdIconModule,
  FdSeparatorModule,
];

@NgModule({
  declarations: [
    AppPageProfileHeaderComponent,
    AppPageProfileFooterComponent,
    AppPageProfileNavbarComponent,
    AppProfileUserCoverImageComponent,
    EditUserProfileComponent,
    EmailChatComponent,
    EditCoverImageComponent,
    UserDetailComponent,
    AddEditDogDialogComponent,
    DogSizeDialogComponent,
    ConfirmDialogComponent,
    AddressDialogComponent,
    PictureGallaryComponent,
    DogHideDeleteComponent,
    NotificationComponent,
    DogListComponent,
    ShareDogDialogComponent,
    DisplayGalleryComponent,
    DisplayVideoComponent,
    DescriptionComponent,
    DescriptionDialogComponent,
    UnsubscribeComponent,
    PaymentAlertComponent,
    TestInfoDialogComponent,
    UserInfoDialogComponent,
    DogCardgComponent,
    PersonCardComponent,
    SubscriptionPlansComponent,
    SubscriptionParentComponent,
    SubscriptionDogSharingComponent,
    CreditPlansComponent,
    IcoSelectboxComponent,
    BillHistoryComponent,
    InfoPopupComponent,
    LastAdDeleteConfPopupComponent,
    UserCreditComponent,
    MembershipCardComponent,
    ProfileSwitcher
  ],
  imports: [
    CommonModule,
    AppCoreModule,
    ImageCropperModule,
    NgxsModule.forFeature([UserState, DogImageGallaryState, DogState, CreditState, ProductState, CareState]),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMaps.apiKey,
      libraries: ['places']
    }),
    GooglePlaceModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    PopoverModule,
    MatToolbarModule,
    LazyLoadImageModule,
    TranslateModule,
    ...FD_IMPORTS,
  ],
  exports: [
    AppPageProfileHeaderComponent,
    AppPageProfileFooterComponent,
    AppPageProfileNavbarComponent,
    AppProfileUserCoverImageComponent,
    UserDetailComponent,
    AddEditDogDialogComponent,
    DogSizeDialogComponent,
    ConfirmDialogComponent,
    DisplayGalleryComponent,
    DisplayVideoComponent,
    DescriptionComponent,
    DescriptionDialogComponent,
    DogListComponent, // to access in all page
    DogCardgComponent,
    UserInfoDialogComponent,
    PersonCardComponent,
    SubscriptionPlansComponent,
    CreditPlansComponent,
    // modules
    MatToolbarModule,
    IcoSelectboxComponent,
    BillHistoryComponent,
    InfoPopupComponent,
    UserCreditComponent,
    MembershipCardComponent
  ],
  providers: [
  ]
})
export class ProfileSharedModule { }
