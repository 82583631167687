import { NgModule } from "@angular/core";
import { FdSeparatorComponent } from "./components/fd-separator/fd-separator.component";

const COMPONENTS = [
  FdSeparatorComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class FdSeparatorModule {}