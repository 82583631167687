import {
    Pipe,
    PipeTransform
 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Pipe ({
    name: 'optionLanguage'
 })

export class OptionLanguagePipe implements PipeTransform {

   constructor(private translateService: TranslateService) { }
   async transform(options: any, args?: any): Promise<any> {
        const changeOption: any[] = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0 ; i < options.length; i++){
            await this.translateService.get(args + options[i].name).pipe(
                tap()
            ).toPromise().then(body => {
                changeOption.push({name: body, value: options[i].value});
            }
            ).catch(err => console.log(err));
        }
        return changeOption;
     }
}
