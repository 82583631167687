import moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'lastActive' })
export class LastActivePipe implements PipeTransform {
  transform(value: string | undefined): string {
    const YEAR_IN_DAYS: number = 366
    const USER_ACTIVE_BEFORE_YEAR: string = 'vor über einem Jahr';

    const lastLoginDate = moment(value);
    if (!value || !lastLoginDate.isValid()) return USER_ACTIVE_BEFORE_YEAR;

    const now = moment();
    const days = now.diff(lastLoginDate, 'days');
    
    return days > YEAR_IN_DAYS ?
      USER_ACTIVE_BEFORE_YEAR :
      lastLoginDate.format('DD.MM.YYYY');
  }
}