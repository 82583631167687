<!-- Prefix -->
<span *ngIf="fdPrefix">
  <ng-container *ngIf="isTemplateRef(fdPrefix); else iconPrefix">
    <ng-container *ngTemplateOutlet="getTemplateRef(fdPrefix)"></ng-container>
  </ng-container>
  <ng-template #iconPrefix>
    <i fd-icon [fdIcon]="ICON_MAP[getString(fdPrefix)]" [fdSize]="16"></i>
  </ng-template>
</span>

<!-- Button Content -->
<span>
  <ng-content></ng-content>
</span>