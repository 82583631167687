import { AfterContentChecked, Directive, ElementRef, Input, OnChanges, Renderer2, SecurityContext, SimpleChanges } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[fd-icon]',
})
export class FdIconDirective implements OnChanges, AfterContentChecked {

  @Input() fdIcon: string = '';
  @Input() fdSize: number = 16;
  @Input() fdColor: string = 'inherit';
  @Input() fdDisabled: boolean = false;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    
    const { fdIcon, fdSize, fdColor, fdDisabled } = changes;

    if (fdIcon || fdSize || fdColor || fdDisabled) {
      this.setIcon();
    }
  }

  ngAfterContentChecked(): void {
    this.iterateSVGs();
  }

  get hostElement(): HTMLElement {
    return this.element.nativeElement;
  }

  private setIcon(): void {
    this.renderer.setProperty(this.hostElement, 'innerHTML', this.sanitizer.sanitize(
      SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.fdIcon))
    );
  }

  public iterateSVGs(): void {
    const { children } = this.hostElement;
    let { length } = children;

    if (!length) { return; }

    while (length--) {
      const child = children[length];
      if (child.tagName.toLowerCase() === 'svg') {
        this.normalizeSVGElement(child as SVGElement);
      }
    }
  }

  public normalizeSVGElement(svg: SVGElement): void {
    for (let i = 0; i < svg.children.length; i++) {
      this.renderer.removeAttribute(svg.children[i], 'fill');
    }

    this.renderer.setAttribute(svg, 'fill', this.fdDisabled ? 'currentColor' : this.fdColor);

    if (!svg.hasAttribute('viewBox')) {
      this.renderer.setAttribute(svg, 'viewBox', '0 0 20 20');
    }

    this.renderer.setAttribute(svg, 'width', `${this.fdSize}px`);
    this.renderer.setAttribute(svg, 'height', `${this.fdSize}px`);
  }
}