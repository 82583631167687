import { Component, EventEmitter, HostListener, Input, Output, TemplateRef, ViewEncapsulation } from "@angular/core";
import { ICON_MAP } from "../../../Icon/icon-map";

export const FD_BUTTON_TYPE = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary'
} as const;

export type FdButtonType = 'primary' | 'secondary' | 'tertiary';

@Component({
    selector: '[fd-button]',
    templateUrl: './fd-button.component.html',
    styleUrls: ['./fd-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
      class: 'fd-button',
      '[class.fd-button-primary]': `fdType === 'primary'`,
      '[class.fd-button-secondary]': `fdType === 'secondary'`,
      '[class.fd-button-tertiary]': `fdType === 'tertiary'`,

      '[class.fd-round]': `fdRounded === true`,
      '[class.fd-block]': `fdBlock === true`,
      '[class.fd-icon-only]': `fdIconOnly === true`,
    }
})
export class FdButtonComponent {
  
  @Input() fdType: FdButtonType = FD_BUTTON_TYPE.PRIMARY;
  @Input() fdBlock: boolean = false;
  @Input() fdIconOnly: boolean = false;
  @Input() fdRounded: boolean = false;
  @Input() fdPrefix: string | TemplateRef<any> | null = null;
  @Output() fdOnClick: EventEmitter<MouseEvent | KeyboardEvent> = new EventEmitter<MouseEvent | KeyboardEvent>();

  @HostListener('mouseup', ['$event'])
  @HostListener('keyup.space', ['$event'])
  @HostListener('keyup.enter', ['$event'])
  public handleClick(event: MouseEvent | KeyboardEvent): void {
    this.fdOnClick.emit(event);
  }
  
  public readonly ICON_MAP: { [key: string]: string } = ICON_MAP;

  public isTemplateRef(value: any): value is TemplateRef<any> {
    return value instanceof TemplateRef;
  }

  public getTemplateRef(value: any): TemplateRef<any> {
    return value as TemplateRef<any>;
  }

  public getString(value: any): string {
    return value as string;
  }
}