import { NgModule } from "@angular/core";

import { FdIconDirective } from "./icon.directive";

const COMPONENTS: any[] = [
  FdIconDirective,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class FdIconModule {}

// Export all the Icons mapping
export * from './icon-map';