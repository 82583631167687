import { NgModule } from "@angular/core";
import { FdButtonComponent } from "./components/fd-button/fd-button.component";
import { CommonModule } from "@angular/common";
import { FdIconModule } from "../Icon/index.module";

const COMPONENTS = [
  FdButtonComponent
];

@NgModule({
  imports: [
    CommonModule,
    FdIconModule,
  ],
  declarations: [
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class FdButtonModule {}