import { NgModule } from "@angular/core";

import { FdCardComponent } from "./components/fd-card/fd-card.component";

const COMPONENTS = [
  FdCardComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class FdCardModule {}